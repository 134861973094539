import { useSessionContext } from '@contexts/session'
import { AVATAR_IMAGE } from '@lib/constants/gamification'
import { GENDER } from '@common/enums'
import { t } from '@lingui/macro'
import classNames from 'classnames'

type Props = {
  image?: string
  name?: string
  size?: 'big' | 'medium' | 'small' | 'tiny'
  userGender?: string
  isNotCurrentUser?: boolean
  username?: string
  imageClass?: string
  className?: string
}

const { MALE, FEMALE } = GENDER
const { MALE_AVATAR, FEMALE_AVATAR, UNSPECIFIED_AVATAR } = AVATAR_IMAGE

/** i18n support added */
const Avatar = ({
  image,
  name,
  size,
  userGender,
  isNotCurrentUser = false,
  imageClass = 'border-white',
  className,
}: Props): JSX.Element => {
  const imageSize =
    size === 'big'
      ? 'h-24 w-24'
      : size === 'medium'
      ? 'h-12 w-12'
      : size === 'tiny'
      ? 'h-6 w-6'
      : 'h-8 w-8'

  const { avatar, gender } = useSessionContext()
  const avatarGender = userGender || (!isNotCurrentUser && gender)
  const avatarFallbackImage =
    avatarGender === MALE
      ? MALE_AVATAR
      : avatarGender === FEMALE
      ? FEMALE_AVATAR
      : UNSPECIFIED_AVATAR

  const avatarImage = image
    ? image
    : isNotCurrentUser
    ? avatarFallbackImage
    : avatar
    ? avatar
    : avatarFallbackImage

  return (
    <div
      className={classNames(
        'flex-shrink-0 flex flex-col items-center justify-center',
        className
      )}
    >
      <img
        src={avatarImage}
        alt={name || t`Profile Image`}
        className={`${imageSize} rounded-full border ${imageClass}`}
      />
      {name && (
        <span className="text-grey-800 capitalize mt-2 text-center">
          {name}
        </span>
      )}
    </div>
  )
}

export default Avatar
