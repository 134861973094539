export const MAX_STUDENT_IN_LEADERBOARD = 5

export const MIN_STUDENT_TO_SHOW_LEADERBOARD = 3

export const GRADE_GROUP = [
  ['Grade 1', 'Grade 2', 'Grade 3'],
  ['Grade 4', 'Grade 5'],
  ['Grade 6', 'Grade 7', 'Grade 8'],
  ['Grade 9', 'Grade 10'],
  ['Grade 11', 'Grade 12'],
  ['Undergraduate'],
]

export const GAMIFICATION_POINTS = {
  class: 20,
  rescheduledClass: 5,
  earlyClassReschedulePenalty: 15,
  lateClassReschedulePenalty: 50,
  didNotShowUpForTheClassPenalty: 100,
  quizOnTimeSubmission: 100,
  quizLateSubmission: 50,
  projectOnTimeSubmission: 100,
  projectLateSubmission: 50,
}

export const STUDENT_GAMIFICATION_POINT_ALLOCATION_TABLE = [
  {
    points: `+${GAMIFICATION_POINTS.class} points`,
    description: 'Attending classes',
  },
  {
    points: `+${GAMIFICATION_POINTS.projectOnTimeSubmission} points`,
    description: 'Project submission on time',
  },
  {
    points: `+${GAMIFICATION_POINTS.projectLateSubmission} points`,
    description: 'Project submission after due date',
  },
  {
    points: `+${GAMIFICATION_POINTS.quizOnTimeSubmission} points`,
    description: 'Quiz submission on time',
  },
  {
    points: `+${GAMIFICATION_POINTS.quizLateSubmission} points`,
    description: 'Quiz submission after due date',
  },
]

export const STUDENT_GAMIFICATION_LEVEL_SCORE_DISTRIBUTION = {
  none: { lowest: Number.NEGATIVE_INFINITY, highest: -1 },
  bronze: { lowest: 0, highest: 4999 },
  silver: { lowest: 5000, highest: 9999 },
  gold: { lowest: 10000, highest: 19999 },
  diamond: { lowest: 20000, highest: Number.POSITIVE_INFINITY },
}

export const STUDENT_GAMIFICATION_LEVEL_NAME = {
  NONE: 'None',
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  DIAMOND: 'Diamond',
}

export const AVATAR_IMAGE = {
  MALE_AVATAR: 'https://fastly.codingal.com/images/gamification/male_user.png',
  FEMALE_AVATAR:
    'https://fastly.codingal.com/images/gamification/female_user.png',
  UNSPECIFIED_AVATAR:
    'https://fastly.codingal.com/images/gamification/unspecified_user.png',
}

export const FALLBACK_IMAGE = '/images/gamification/fallback.jpg'

export const CLASS_LEADERBOARD_PAGE_SIZE = 50

export const TEACHER_GAMIFICATION_LEVEL_SCORE_DISTRIBUTION = {
  none: { lowest: Number.NEGATIVE_INFINITY, highest: -1 },
  bronze: { lowest: 0, highest: 24999 },
  silver: { lowest: 25000, highest: 74999 },
  gold: { lowest: 75000, highest: 149999 },
  diamond: { lowest: 150000, highest: 299999 },
  platinum: { lowest: 300000, highest: 499999 },
  sapphire: { lowest: 500000, highest: 799999 },
  emerald: { lowest: 800000, highest: Number.POSITIVE_INFINITY },
}

export const TEACHER_GAMIFICATION_LEVEL_NAME = {
  NONE: 'None',
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  DIAMOND: 'Diamond',
  PLATINUM: 'Platinum',
  SAPPHIRE: 'Sapphire',
  EMERALD: 'Emerald',
}

export const TEACHER_GAMIFICAION_LEVEL_ENUM = {
  none: 0,
  bronze: 1,
  silver: 2,
  gold: 3,
  diamond: 4,
  platinum: 5,
  sapphire: 6,
  emerald: 7,
}

export const TEACHER_GAMIFICAION_POINTS_DISTRIBUTION = {
  CLASS_JOIN_LATE: -100,
  DID_NOT_JOIN_CLASS: -200,
  CLASS_JOIN_ON_TIME_AND_COMPLETING_CLASS: 50,
  CLASS_JOIN_ON_TIME_AND_NOT_COMPLETING_CLASS: 20,
  STUDENT_ENROLLMENT: 500,
  PROJECT_REVIEW_LATE: -100,
  PROJECT_REVIEW_ON_TIME: 50,
  THREE_STAR_OR_BELOW_RATING: -200, // Rating given by student to teacher
  FOUR_STAR_RATING: 50,
  FIVE_STAR_RATING: 100,
  INSTANT_CLASS_REQUEST_ACCEPTED: 20,
}

export const MAX_TEACHER_IN_LEADERBOARD = 5

export const TEACHER_GAMIFICATION_POINT_ALLOCATION_TABLE = [
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.INSTANT_CLASS_REQUEST_ACCEPTED} points`,
    description: 'Accepting an instant trial class request',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.CLASS_JOIN_ON_TIME_AND_COMPLETING_CLASS} points`,
    description: 'Joining on time (before 1 min) and completing the class',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.CLASS_JOIN_ON_TIME_AND_NOT_COMPLETING_CLASS} points`,
    description: 'Joining on time (before 1 min) and not completing the class',
  },
  {
    points: `${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.CLASS_JOIN_LATE} points`,
    description: 'Joining late (after 1 min)',
  },
  {
    points: `${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.DID_NOT_JOIN_CLASS} points`,
    description: 'Not joining a class',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.PROJECT_REVIEW_ON_TIME} points`,
    description: 'Reviewing project on time',
  },
  {
    points: `${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.PROJECT_REVIEW_LATE} points`,
    description: 'Reviewing project late',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.STUDENT_ENROLLMENT} points`,
    description: 'Converting a student',
  },
  {
    points: `${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.THREE_STAR_OR_BELOW_RATING} points`,
    description: 'Student has given <=3 star rating',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.FOUR_STAR_RATING} points`,
    description: 'Student has given 4 star rating',
  },
  {
    points: `+${TEACHER_GAMIFICAION_POINTS_DISTRIBUTION.FIVE_STAR_RATING} points`,
    description: 'Student has given 5 star rating',
  },
]

export const TEACHER_DASHBOARD_IMAGE_PATH = '/images/teacher-dashboard/'
