import React, { useState } from 'react'

import Avatar from '@components/common/avatar'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  AVATAR_CROP_MODAL_ID,
  MOD_LOGIN_MODAL_ID,
  PASSWORD_RESET_MODAL_ID,
} from '@common/constants'
import { checkIsDOMNodeChildOf } from '@lib/utils/common'
import { ChevronDown } from 'react-feather'

type Props = {
  triggerLabel?: string
  children: JSX.Element | JSX.Element[]
  isDark: boolean
} & typeof defaultProps

const defaultProps = {
  isDark: false,
}

const SimpleDropdown = ({
  triggerLabel,
  isDark,
  children,
}: Props): JSX.Element => {
  const [active, setActive] = useState(false)
  const textColor = isDark ? 'text-white' : 'text-grey'
  return (
    <div className="relative inline-block text-left">
      <OutsideClickHandler
        onOutsideClick={(event: any) => {
          if (!active) return

          const isClickedInsideAvatarModal = checkIsDOMNodeChildOf({
            node: event.target,
            parentId: AVATAR_CROP_MODAL_ID,
          })

          const isClickedInsideModLoginModal = checkIsDOMNodeChildOf({
            node: event.target,
            parentId: MOD_LOGIN_MODAL_ID,
          })

          const isClickedInsidePassModal = checkIsDOMNodeChildOf({
            node: event.target,
            parentId: PASSWORD_RESET_MODAL_ID,
          })

          setActive(
            isClickedInsideAvatarModal ||
              isClickedInsideModLoginModal ||
              isClickedInsidePassModal
          )
        }}
      >
        <div>
          <span className="rounded-md">
            <button
              type="button"
              className="inline-flex items-center justify-center w-full leading-5 transition duration-150 ease-in-out rounded-md text-md text-grey-900 focus:outline-none active:bg-orange-100 active:text-grey-900"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setActive(!active)}
            >
              <div className="flex items-center">
                <span
                  data-testid="navbar-username"
                  className={`font-600 text-lg mr-1 md:mr-2 ${textColor}`}
                >
                  {triggerLabel}
                </span>
                <Avatar />
              </div>
              <ChevronDown size={14} className={`ml-1 ${textColor}`} />
            </button>
          </span>
        </div>
        {active && (
          <div className="absolute right-0 z-30 w-48 mt-2 origin-top-right rounded-md shadow-xl">
            <div className="bg-white rounded-md shadow-xs">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {children}
              </div>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  )
}

SimpleDropdown.defaultProps = defaultProps
export default SimpleDropdown
