import Link from 'next/link'
import { useRouter } from 'next/router'
import nookies from 'nookies'

import Logo from '@components/common/logo'
import { useSessionContext } from '@contexts/session'
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from '@common/constants'

import SimpleDropdown from '@components/common/dropdown/simple-dropdown'
import { ROLES } from '@lib/enums/dashboard'
import useIsIndia from '@hooks/useIsIndia'
import { useState } from 'react'
import AuthModal from '@containers/competitions/auth-modal.dynamic'
import classNames from 'classnames'
import { Mail, Phone } from 'react-feather'
import { t, Trans } from '@lingui/macro'
import LanguageSwitcher from '@components/common/language-switcher'

type Props = {
  role?: string
  bgColor?: string
}

export const RegistrationNavBar = ({ role, bgColor = 'bg-white' }: Props) => {
  const router = useRouter()
  // Used to take phone number if it is not present in the query param
  const [isTeacherAuthModalOpen, setIsTeacherAuthModalOpen] = useState(false)
  const queryParams = router.asPath?.split('?')[1]
  const handleLogout = () => {
    nookies.destroy(null, 'token', { path: '/' })
    router.push('/')
  }

  const handleTeacherRegisterRedirect = () => {
    if (queryParams?.includes('phone')) {
      router.push(`/register/teacher/?${queryParams}`)
      return
    }
    setIsTeacherAuthModalOpen(true)
  }

  const { name, loggedIn } = useSessionContext()

  const { isIndia } = useIsIndia()
  return (
    <div
      className={classNames(
        'container flex content-center justify-between p-4 mx-auto lg:bg-transparent',
        bgColor
      )}
    >
      <Link
        href={loggedIn ? '/dashboard/' : '/'}
        prefetch={loggedIn ? true : false}
      >
        <a className="text-xl text-grey-900 font-600">
          <Logo
            withText
            alt={t`Codingal Logo`}
            style={{ width: '150px', height: '35px' }}
          />
        </a>
      </Link>
      <div className="flex items-center">
        {role === ROLES.STUDENT && (
          <span className="items-center space-x-2 md:flex">
            <button
              onClick={handleTeacherRegisterRedirect}
              className="text-orange hover:underline focus:outline-none leading-none"
            >
              <Trans id="trialClass.navbar.areYouTeacher">
                Are you a teacher?
              </Trans>
            </button>
          </span>
        )}

        {role === ROLES.TEACHER && (
          <span className="items-center space-x-2 md:flex">
            <Link href={`/register/?${queryParams}`}>
              <a className="text-orange hover:underline leading-none">
                <Trans id="trialClass.navbar.areYouStudent">
                  Are you a student?
                </Trans>
              </a>
            </Link>
          </span>
        )}

        {role && (
          <span className="items-center hidden mx-4 space-x-2 md:flex">
            <span className="text-grey">|</span>
          </span>
        )}
        <LanguageSwitcher className="mr-2 lg:mr-5" />
        <span className="items-center hidden space-x-2 md:flex">
          {isIndia ? (
            <Phone className="text-blue-200" size={16} />
          ) : (
            <Mail className="text-blue-200" size={16} />
          )}
          <span className="text-blue-200">
            <Trans id="trialClass.navbar.support">Support:</Trans>{' '}
            <a
              href={
                isIndia
                  ? `tel:${SUPPORT_PHONE_NUMBER.value}`
                  : `mailto:${SUPPORT_EMAIL.value}`
              }
              className="hover:underline"
            >
              {isIndia ? SUPPORT_PHONE_NUMBER.label : SUPPORT_EMAIL.label}
            </a>
          </span>
        </span>
        {loggedIn && (
          <>
            <span className="hidden h-full mx-4 border border-grey-300 md:block" />
            <SimpleDropdown triggerLabel={name.split(' ')[0]}>
              {/* <Link href="/dasboard/">
              <a
                className="block px-4 py-2 text-sm leading-5 text-grey-900 hover:bg-orange-100 focus:outline-none focus:bg-orange-100"
                role="menuitem"
              >
                Account settings
              </a>
            </Link> */}
              <button
                type="submit"
                className="block w-full px-4 py-2 text-sm leading-5 text-left text-grey-900 hover:bg-orange-100 focus:outline-none focus:bg-orange-100"
                role="menuitem"
                onClick={handleLogout}
              >
                <Trans id="trialClass.navbar.logout">Logout</Trans>
              </button>
            </SimpleDropdown>
          </>
        )}
      </div>
      <AuthModal
        isUserTeacher
        isOpen={isTeacherAuthModalOpen}
        setIsOpen={setIsTeacherAuthModalOpen}
      />
    </div>
  )
}

export default RegistrationNavBar
